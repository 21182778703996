<template>
  <v-container>
    <v-row justify="center">
      <img alt="logo" src="@/assets/imgs/logo.svg" class="logo" />
    </v-row>
    <v-row justify="center">
      <login-form />
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Login",

  components: {
    LoginForm,
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/views/login.scss">

</style>
